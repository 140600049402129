<template data-app>
  <v-container>
    <v-card>
      <v-card-title>Блог</v-card-title>
      <v-card-actions style="padding: 0 16px;">
        <v-btn @click="toBlog">Создать</v-btn>
      </v-card-actions>
      <v-card-text>
        <v-data-table
            hover
            :items="items"
            :headers="headers"
            item-key="n_id"
            :loading="loading"
            :footer-props="{'items-per-page-options':[-1]}"
            no-data-text="Статей нет"
            loading-text="Загрузка..."
            mobile-breakpoint="0"
            @click:row="toBlog"
        >
          <template v-slot:item.status="{item}">
            <span>{{item.status === '0' ? 'Не опубликовано' : 'Опубликовано'}}</span>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
export default {
  name: "Table",
  data() {
    return {
      items: [],
      loading: false,
      headers: [
        {text: '№', value: 'n_id'},
        {text: 'Тема', value: 'title'},
        {text: 'Статус', value: 'status'},
        {text: 'Дата', value: 'date'},
      ]
    }
  },
  methods: {
    get() {
      this.loading = true;
      this.$store.state.server.request('blog/get', {order: 'desc'}, (data)=>{
        this.loading = false;
        this.items = data.response;
      }, ()=>{
        this.loading = false;
      });
    },
    toBlog(item) {
      if (item.n_id) this.$router.push(`/blog/${item.n_id}`);
      else this.$router.push('/blog/-1');
    }
  },
  mounted() {
    this.get();
  }
}
</script>

<style scoped>

</style>
